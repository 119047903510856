export const testIds = {
  appConfig: {
    container: 'data-testid ac-container',
    feUrl: 'data-testid ac-fe-url',
    beUrl: 'data-testid ac-be-url',
    userToken: 'data-testid ac-user-token',
    userEmail: 'data-testid ac-user-email',
    submit: 'data-testid ac-submit-form',
  },
};
