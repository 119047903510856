import React, { ChangeEvent, useState, useEffect } from 'react';
import { css } from '@emotion/css';
import { AppPluginMeta, GrafanaTheme2, PluginConfigPageProps, PluginMeta } from '@grafana/data';
import { getBackendSrv, config } from '@grafana/runtime';
import { Button, Field, FieldSet, Input, SecretInput, useStyles2, Alert } from '@grafana/ui';
import { testIds } from '../testIds';
import semver from 'semver';

export type AppPluginSettings = {
  beUrl?: string;
  feUrl?: string;
  userEmail?: string;
  firstVisit?: boolean;
  allowedHosts?: string[];
  httpHeaderName1?: string;
};

type State = {
  beUrl: string;
  feUrl: string;
  userToken: string;
  userEmail: string;
  authIsSet: boolean;
  infinityIsInstalled: boolean;
  dashboardIsImported?: boolean;
  dataSourceIsAdded?: boolean;
  grafanaVersionIsValid: boolean;
  dataSourceVersionIsValid: boolean;
};

const pluginId = 'instabug-instabug-app'
const infinityDataSourceName = 'ibg-infinity-datasource';
const infinityId = 'yesoreyeram-infinity-datasource';
const infinityDataSourceUid = 'ibg-infinity-datasource-IBG123';
const appHealthDashboardUid = 'instabug-apphealth-dashboard-IBG123';
const minGrafanaVersion = '10.0.0';
const minInfinityVersion = '2.4.0';
const defaultHttpHeaderName1 = "Authorization"
// const defaultHttpHeaderValue1 = "Token token=\"VgfcHDHXrzxnqGKya33J\" , email=\"demo-guest@instabug.com\""
const defaultDashboardConfig = {
  beUrl: "https://dashboard-api.instabug.com",
  feUrl: "https://dashboard.instabug.com"
}
const defaultDataSourceConfig = {
  name: infinityDataSourceName,
  uid: infinityDataSourceUid,
  type: infinityId,
  url: "",
  access: "proxy",
  basicAuth: false,
  basicAuthUser: "",
  database: "",
  isDefault: false,
  withCredentials: false,
  readOnly: false,
  jsonData: {
    allowedHosts: [
      defaultDashboardConfig.beUrl,
      defaultDashboardConfig.feUrl
    ],
    userEmail: null,
    httpHeaderName1: defaultHttpHeaderName1
  },
  secureJsonData: {
    httpHeaderValue1: null
  }
}


export interface AppConfigProps extends PluginConfigPageProps<AppPluginMeta<AppPluginSettings>> {}

export const AppConfig = ({ plugin }: AppConfigProps) => {
  const s = useStyles2(getStyles);
  const { enabled, pinned } = plugin.meta;
  const [state, setState] = useState<State>({
    beUrl: '',
    feUrl: '',
    userToken: '',
    userEmail: '',
    authIsSet: false,
    infinityIsInstalled: true,
    dashboardIsImported: true,
    dataSourceIsAdded: true,
    grafanaVersionIsValid: true,
    dataSourceVersionIsValid: true,
  });
  const [inputError, setInputError] = useState('');

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value.trim(),
    });
  };

  const onAuthReset = () => {
    setState({
      ...state,
      userToken: '',
      authIsSet: false,
    });
  };

  const validGrafanaVersion = async () => {
    const grafanaVersion = config.buildInfo.version;
    return semver.gte(grafanaVersion, minGrafanaVersion);
  }

  const validInfinityVersion = async () => {
    const res = await getBackendSrv().get(`/api/plugins/${infinityId}/settings`);
    return semver.gte(res.info.version, minInfinityVersion);
  }

  const firstVisit = async () => {
    const pluginJsonData = plugin.meta.jsonData || {}
    return ('firstVisit' in pluginJsonData)? Boolean(pluginJsonData.firstVisit): true;
  }

  const dashboardInitialConfig = () => {
    const newDashboardConfig = Object.assign({}, defaultDashboardConfig);
    const mergedConfig = {
      beUrl: plugin.meta.jsonData?.beUrl || newDashboardConfig.beUrl,
      feUrl: plugin.meta.jsonData?.feUrl || newDashboardConfig.feUrl
    }
    Object.assign(newDashboardConfig, mergedConfig);
    return [newDashboardConfig.beUrl, newDashboardConfig.feUrl];
  }

  const dashboardConfig = async () => {
    let beUrl, feUrl;
    try {
      const currentDashboard = await getBackendSrv().get(
        `/api/dashboards/uid/${appHealthDashboardUid}`
      );
      beUrl = currentDashboard.dashboard.templating.list.find((a: any) => a.name === 'be_host').query
      feUrl = currentDashboard.dashboard.templating.list.find((a: any) => a.name === 'dashboard_link').query
    }
    catch(error) {}

    return [beUrl, feUrl]
  }

  const updateDashboardWithInitialConfig = async () => {
    const [beUrl, feUrl] = dashboardInitialConfig();
    await updateDashboard(plugin.meta.id, {jsonData: { beUrl, feUrl }});
  }

  const importDashboard = async () => {
    try{
      await getBackendSrv().post(`/api/dashboards/import`, {
        pluginId: pluginId,
        path: "dashboards/instabug-appHealth.json",
        overwrite: false,
        inputs: [],
      });

      return true;
    }
    catch(error){
      // Error while importing dashboard /api/dashboards/import
    }
    return false;
  }

  const dataSourceInitialConfig = () => {
    return defaultDataSourceConfig;
  }

  const dataSourceConfig = async () => {
    let authIsSet, userEmail, allowedHosts;
    try {
      const currentDataSource = await getBackendSrv().get(
        `/api/datasources/uid/${infinityDataSourceUid}`
      );
      authIsSet = !!currentDataSource.secureJsonFields?.httpHeaderValue1;
      userEmail = currentDataSource.jsonData?.userEmail;
      allowedHosts = currentDataSource.jsonData?.allowedHosts;
    }
    catch(error) {
      // Fatal Err
    }
    
    return [authIsSet, userEmail, allowedHosts];
  }

  const addNewDataSource = async () => {
    const newDataSourceConfig = dataSourceInitialConfig();

    try{
      await getBackendSrv().post(
        `/api/datasources`,
        newDataSourceConfig
      );
      return true;
    }
    catch(error) {
      // Fatal Err
    }
    return false;
  }

  useEffect(() => {
    if (!enabled) { return; }
    const preflight = async () => {
      // True if it's the first time to visit the config page
      const pageFirstVisit = await firstVisit();
      let {
        beUrl,
        feUrl,
        userEmail,
        authIsSet,
        dataSourceIsAdded,
        dashboardIsImported,
      } = state;

      // Validating dependencies
      if(!await validGrafanaVersion()) {
        setState({
          ...state,
          grafanaVersionIsValid: false,
        });
        return;
      }

      if(!await infinityInstalled()) {
        setState({
          ...state,
          infinityIsInstalled: false,
        });
        return;
      }
      
      if(!await validInfinityVersion()) {
        setState({
          ...state,
          dataSourceVersionIsValid: false,
        });
        return;
      }

      // Initialize dashboard
      if(!await dashboardImported()) {
        if(!pageFirstVisit || !await importDashboard()) {
          // This happens when the user manually deletes the dashboard
          // Or when it's the users first visit and the dashboard importing failed
          setState({
            ...state,
            dashboardIsImported: false,
          });
          return;
        }
      }

      if(pageFirstVisit) {
        await updateDashboardWithInitialConfig();
      }

      [beUrl, feUrl] = await dashboardConfig();

      // Initialize data source
      if(!await dataSourceAdded()) {
        if(!pageFirstVisit || !await addNewDataSource()) {
          // This happens when the user manually deletes the data source
          // Or when it's the users first visit and the data source creation failed
          setState({
            ...state,
            dataSourceIsAdded: false,
          });
          return;
        }
      }

      [authIsSet, userEmail] = await dataSourceConfig();

      // initialize state
      setState({
        ...state,
        beUrl,
        feUrl,
        userEmail,
        authIsSet,
        dataSourceIsAdded,
        dashboardIsImported,
      });

      if (pageFirstVisit) {
        await updatePlugin(plugin.meta.id, {
          enabled,
          pinned,
          jsonData: {
            firstVisit: false,
          }
        });
      }
    };
    preflight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-testid={testIds.appConfig.container} hidden={!enabled}>
        <Alert severity='warning' title='' hidden={state.infinityIsInstalled}>
          You must install <a href='/plugins/yesoreyeram-infinity-datasource' target="_blank" rel="noopener noreferrer" className={s.link}>Infinity Plugin</a>&nbsp;
          then refresh the page.
        </Alert>
        
        <Alert severity='warning' title='' hidden={state.dashboardIsImported}>
          You must import <a href='/plugins/instabug-instabug-app?page=dashboards' target="_blank" rel="noopener noreferrer" className={s.link}>instabug app health dashboard</a>&nbsp;
          then refresh the page.
        </Alert>
        <Alert severity='warning' title='' hidden={state.dataSourceIsAdded}>
          You don&apos;t have {infinityDataSourceName}. Please re-install the plugin.
        </Alert>
        <Alert severity='warning' title='' hidden={state.grafanaVersionIsValid}>
          Please install Grafana version {minGrafanaVersion} or higher.
        </Alert>
        <Alert severity='warning' title='' hidden={state.dataSourceVersionIsValid}>
          Please install Infinity plugin version {minInfinityVersion} or higher.
        </Alert>
        <Alert severity='info' title='' hidden={errorState(state)}>
          Contact the support team at <a href="mailto:support@instabug.com" target="_blank" rel="noopener noreferrer" className={s.link}>support@instabug.com</a> to get the necessary fields for configuration.
        </Alert>
      <FieldSet label="Configuration" disabled={errorState(state)}>
        <Field label="API link" description="Instabug’s backend API endpoint">
          <Input
            width={60}
            name="beUrl"
            id="config-api-url"
            data-testid={testIds.appConfig.beUrl}
            value={state.beUrl}
            placeholder={`E.g.: https://dashboard-api.instabug.com`}
            onChange={onChange}
          />
        </Field>
        <Field label="Dashboard URL" description="Link to your Instabug dashboard">
          <Input
            width={60}
            name="feUrl"
            id="config-api-url"
            data-testid={testIds.appConfig.feUrl}
            value={state.feUrl}
            placeholder={`E.g.: https://dashboard.instabug.com`}
            onChange={onChange}
          />
        </Field>
        <Field label="Authentication token" description="Token used for accessing Instabug’s API">
          <SecretInput
            width={60}
            id="config-user-token"
            data-testid={testIds.appConfig.userToken}
            name="userToken"
            value={state.userToken}
            placeholder={'Your secret API token'}
            onChange={onChange}
            onReset={onAuthReset}
            isConfigured={Boolean(state.authIsSet)}
          />
        </Field>
        <Field label="Email" description="Instabug email for Grafana integration">
          <Input
            width={60}
            name="userEmail"
            id="config-user-email"
            data-testid={testIds.appConfig.userEmail}
            value={state.userEmail}
            placeholder={state.userEmail || `E.g.: you@company.com`}
            onChange={onChange}
            disabled={state.authIsSet}
          />
        </Field>

        <div className={s.marginTop}>
          <Button
            type="submit"
            data-testid={testIds.appConfig.submit}
            onClick={() =>
              updatePluginAndReload(plugin.meta.id, !state.authIsSet, {
                enabled,
                pinned,
                jsonData: {
                  beUrl: state.beUrl,
                  feUrl: state.feUrl,
                  userEmail: state.userEmail,
                  firstVisit: false
                },
                // This cannot be queried later by the frontend.
                // We don't want to override it in case it was set previously and left untouched now.
                secureJsonData: {
                  userToken: state.userToken
                }
              }, setInputError)
            }
          >
            Apply settings
          </Button>
          <br/><br/>
          {inputError && <Alert severity='warning' title=''>{inputError}</Alert>}
        </div>
      </FieldSet>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  marginTop: css`
    margin-top: ${theme.spacing(3)};
  `,
  link: css`
    color: #6e9fff;
    text-decoration: underline;
  `
});

const updatePluginAndReload = async (pluginId: string, authChanged: boolean, data: Partial<PluginMeta<AppPluginSettings>>, setInputError: React.Dispatch<React.SetStateAction<string>>) => {
  try {
    if(!validInputs(data, authChanged, setInputError)) { return; }
    const dataSourceUpdated = await updateDataSource(data, authChanged);
    const dashboardUpdated = await updateDashboard(pluginId, data);
    // const pluginUpdated = await updatePlugin(pluginId, data);

    // Reloading the page as the changes made here wouldn't be propagated to the actual plugin otherwise.
    // This is not ideal, however unfortunately currently there is no supported way for updating the plugin state.
    // if(dataSourceUpdated && dashboardUpdated && pluginUpdated) {
    if(dataSourceUpdated && dashboardUpdated) {

      window.location.reload();
    }
  } catch (error) {
    // Could not update the plugin
  }
};

const errorState = (state: State)=> {
  return !state.infinityIsInstalled || !state.dashboardIsImported || !state.dataSourceIsAdded 
  || !state.grafanaVersionIsValid || !state.dataSourceVersionIsValid;
}

const updateDataSource = async (data: Partial<PluginMeta<AppPluginSettings>>, authChanged: boolean) => {
  try {
    const currentDataSource = await getBackendSrv().get(
      `/api/datasources/uid/${infinityDataSourceUid}`
    );
    const allowedHosts = [ data.jsonData?.beUrl, data.jsonData?.feUrl ]
    currentDataSource.jsonData.allowedHosts = allowedHosts;

    if(authChanged) {
      const token = data.secureJsonData?.userToken
      const email = data.jsonData?.userEmail
      currentDataSource.jsonData.userEmail = email;
      currentDataSource.secureJsonData = currentDataSource.secureJsonFields;
      currentDataSource.jsonData.httpHeaderName1 = defaultHttpHeaderName1;
      currentDataSource.secureJsonData.httpHeaderValue1 = `Token token="${token}" , email="${email}"`;
    }
    delete currentDataSource.secureJsonFields;

    await getBackendSrv().put(
      `/api/datasources/uid/${infinityDataSourceUid}`,
      currentDataSource
    );
    return true;
  } catch (error) {
    // Error updating data source
  }
  return false;
};

const updateDashboard = async (pluginId: string, data: Partial<PluginMeta<AppPluginSettings>>) => {
  try{
    const currentDashboard = await getBackendSrv().get(`/api/dashboards/uid/${appHealthDashboardUid}`);
    const beHost = currentDashboard.dashboard.templating.list.find((a: any) => a.name === 'be_host');
    const dashboardLink = currentDashboard.dashboard.templating.list.find((a: any) => a.name === 'dashboard_link');
    beHost.query = data.jsonData?.beUrl;
    dashboardLink.query = data.jsonData?.feUrl;
    currentDashboard.overwrite = true;
    await getBackendSrv().post(`/api/dashboards/db`, currentDashboard);
    return true;
  }
  catch(error){
    // Error updating dashboard
  }
  return false;
};

const updatePlugin = async (pluginId: string, data: Partial<PluginMeta<AppPluginSettings>>) => {
  try{
    await getBackendSrv().post(`/api/plugins/${pluginId}/settings`, data);
    return true;
  }
  catch(error){
    // "Error updating plugin
  }
  return false;
};

const validInputs = function(data: Partial<PluginMeta<AppPluginSettings>>, authChanged: boolean, setInputError: React.Dispatch<React.SetStateAction<string>>): boolean {
  let emailIsValid = true;
  let tokenIsValid = true;
  if(Boolean(authChanged)) {
    emailIsValid = validateEmail(String(data.jsonData?.userEmail), setInputError);
    tokenIsValid = validateToken(String(data.secureJsonData?.userToken), setInputError);
  }
  const beUrlIsValid = validateUrl(String(data.jsonData?.beUrl), 'api link', setInputError);
  const feUrlIsValid = validateUrl(String(data.jsonData?.feUrl), 'dashboard link', setInputError);
  return emailIsValid && tokenIsValid && beUrlIsValid && feUrlIsValid;
}

const validateEmail = function(email: string, setInputError: React.Dispatch<React.SetStateAction<string>>): boolean{
  if(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(String(email))) { return true; }
  setInputError("Invalid Email.");
  return false;
}

const validateUrl = function(url: string, type: string, setInputError: React.Dispatch<React.SetStateAction<string>>): boolean{
  try {
    new URL(String(url));
    return true;
  } catch (err) {
    setInputError(`Invalid ${type} URL.`)
    return false;
  }
}

const validateToken = function(token: string, setInputError: React.Dispatch<React.SetStateAction<string>>): boolean{
  if(token && token !== '') { return true; }
  setInputError("Invalid token");
  return false;
}

const infinityInstalled = async function(): Promise<boolean> {
  try{
    const response = await getBackendSrv().get(`/api/plugins`);
    if (response.some((plugin: any) => plugin.id === infinityId)) { return true }
  }
  catch(error){}
  return false;
}

const dashboardImported = async function(): Promise<boolean> {
  try{
    await getBackendSrv().get(`/api/dashboards/uid/${appHealthDashboardUid}`);
    return true;
  }
  catch(error){}
  
  return false;
}

const dataSourceAdded = async function(): Promise<boolean> {
  try{
    await getBackendSrv().get(`/api/datasources/uid/${infinityDataSourceUid}`);
    return true;
  }
  catch(error){}
  
  return false;
}

