import React from 'react';
import { Routes } from 'react-router-dom';
import { AppRootProps } from '@grafana/data';

export function App(props: AppRootProps) {
  return (
    <Routes>
    </Routes>
  );
}
